<template>
  <div class="case-details">
    <van-nav-bar
      title=""
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      safe-area-inset-top
      :border="false"
    />

    <div class="video-box">
      <!-- <video-play :options="videoOptions" /> -->
      <video
        :src="videoUrl"
        controls="controls"
        preload="auto"
        :poster="indexUrl"
      >
        your browser does not support the video tag
      </video>
    </div>

    <div class="title">
      {{ name }}
    </div>
  </div>
</template>

<script setup>
import { reactive } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import VideoPlayer from "@/components/videoPlay";
import VideoPlay from "../../components/videoPlay.vue";
const router = useRouter();
const route = useRoute();

const indexUrl = route.query.indexUrl;
const name = route.query.name;
const videoUrl = route.query.videoUrl;
const videoOptions = {
  autoplay: true, //自动播放
  loop: true, //视频一结束就重新开始
  muted: false, //默认情况下将使所有音频静音
  aspectRatio: "16:9", //显示比率
  fullscreen: {
    options: { navigationUI: "hide" },
  },
  sources: [
    {
      src: videoUrl,
      type: "video/mp4",
    },
  ],
};
const loadCaseDetails = async () => {};
const onClickLeft = () => {
  router.back();
};
VideoPlay;
</script>

<style lang="scss" scoped>
.video-box {
  width: 100%;
  height: calc(100vh - 50px);
  background: #000;
  video {
    width: 375px;
    height: 100%;
    object-fit: contain;
  }
}
.title {
  position: fixed;
  bottom: 80px;
  left: 20px;
  font-size: 14px;
  color: #fff;
  text-align: left;
}
.video-js.vjs-fluid {
  height: 100%;
}
</style>
